import React, { useEffect } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { useTranslation } from "react-i18next";
import Layout from "./utils/Layout";
import Index from "./pages/Index";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Help from "./pages/Help";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import SetNewPassword from "./pages/SetNewPassword";
import GenerateMaze from "./pages/GenerateMaze";
import CheckMaze from "./pages/CheckMaze";
import SolveMaze from "./pages/SolveMaze";
import Account from "./pages/Account";
import NoPage from "./pages/NoPage";
import AuthChecker from "./utils/AuthChecker";

/**
 * App is the root component of the application.
 * It sets up the routing for the application and renders the appropriate page component based on the current route.
 * It also sets the language of the document.
 *
 * @returns {React.Element} The App component.
 */
export default function App() {
  const { i18n } = useTranslation();

  //Set the language of the document whenever the language changes.
  useEffect(() => {
    document.documentElement.lang = i18n.resolvedLanguage;
  }, [i18n.resolvedLanguage]);

  //Render the application.
  return (
    <React.StrictMode>
      <React.Suspense fallback={<Loading loading background="#fedf19" loaderColor="#7d141d" />}></React.Suspense>
      <RouterProvider router={createBrowserRouter([
          {
            path: "/",
            element: <Layout />,
            children: [
              {
                index: true,
                Component: Index,
              },
              {
                path: "privacy-policy",
                Component: PrivacyPolicy,
              },
              {
                path: "terms-and-conditions",
                Component: TermsAndConditions,
              },
              {
                path: "help",
                Component: Help,
              },
              {
                path: "signup",
                Component: Signup,
              },
              {
                path: "login",
                Component: Login,
              },
              {
                path: "reset-password",
                Component: ResetPassword,
              },
              {
                path: "set-new-password",
                Component: SetNewPassword,
              },
              {
                path: "generate-maze",
                Component: GenerateMaze,
              },
              {
                path: "check-maze",
                Component: CheckMaze,
              },
              {
                path: "solve-maze",
                Component: SolveMaze,
              },
              {
                path: "account",
                element: <AuthChecker Component={Account} url={"account"} />,
              },
              {
                path: "*",
                Component: NoPage,
              },
            ],
          },
        ])} />
    </React.StrictMode>
  );
}
