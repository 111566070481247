import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Col, Row, Button } from "react-bootstrap";

/**
 * ResultType is an enumeration for the type of results to display.
 */
const ResultType = {
  COMPUTATIONS: 0,
  PATH: 1
};


/**
 * CheckResults renders the results of checking a maze.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.data - The data of the maze to display. It should have the following properties:
 * - width: The width of the maze.
 * - height: The height of the maze.
 * - start: The start point of the maze.
 * - end: The end point of the maze.
 * @param {Object} props.data.data - The data of the maze with the following properties:
 *   - result: The result of the computation.
 *   - expectedResult: The expected result of the computation.
 *   - operation: The operation to compute the result.
 *   - isUserPath: Whether the cell is part of the user"s path.
 *   - isMazePath: Whether the cell is part of the maze"s path.
 * @param {Object} props.data.info - Additional information about the maze with the following properties:
 *   - userType: The type of user who solved the maze.
 *   - solutionId: The ID of the solution.
 *   - mazeId: The ID of the maze.
 *   - nickname: The nickname of the user who solved the maze.
 *   - solvedAt: The date when the maze was solved.
 *   - correct: The number of correct computations.
 *   - incorrect: The number of incorrect computations.
 *   - correctPath: The number of correct path cells.
 *   - missedPath: The number of missed path cells.
 *   - wrongPath: The number of wrong path cells.
 *
 * @returns {React.Element} The CheckResults component.
 */
export default function CheckResults({ data }) {
  //Check the parameters
  checkParameters(data);


  //Localisation
  const { i18n, t } = useTranslation();


  //Currently displayed results type
  const [resultType, setResultType] = useState(ResultType.COMPUTATIONS);


  //Render the component
  return (
    <>
      {data.info.userType && <Alert variant="info">
        {data.info.userType === "ADMIN" ?
          <Trans i18nKey="maze-check-info-admin">You can see the correct and your solutions below. This maze was generated by a not-logged-in user, but you can refer to your solution by these values: <ul><li>Solution ID: <b>{{solutionId: data.info.solutionId}}</b></li><li>Maze ID: <b>{{mazeId: data.info.mazeId}}</b></li><li>Nickname: <b>{{nickname: data.info.nickname}}</b></li></ul>Please note these values, because you will not be able to see them again.</Trans>
        : (data.info.userType === "ME" ?
            <Trans i18nKey="maze-check-info-me">You can see the correct and your solutions below. You can see these on your profile page as well since you generated this maze. However, you can refer to your solution by these values: <ul><li>Solution ID: <b>{{solutionId: data.info.solutionId}}</b></li><li>Maze ID: <b>{{mazeId: data.info.mazeId}}</b></li><li>Nickname: <b>{{nickname: data.info.nickname}}</b></li></ul></Trans>
          :
            <Trans i18nKey="maze-check-info-not-admin">You can see the correct and your solutions below. The user who generated this maze can see your solutions. You can refer to your solution by these values: <ul><li>Solution ID: <b>{{solutionId: data.info.solutionId}}</b></li><li>Maze ID: <b>{{mazeId: data.info.mazeId}}</b></li><li>Nickname: <b>{{nickname: data.info.nickname}}</b></li></ul>Please note these values, because you will not be able to see them again.</Trans>
          )
        }</Alert>
      }

      <Alert variant={(data.info.missedPath === 0 && data.info.wrongPath === 0) ? "success" : "warning"}>
        {data.info.solvedAt &&
          <p>
            {t("maze-generate-nickname")}: <b>{data.info.nickname}</b><br />
            {t("maze-check-solved-at", { date: new Date(data.info.solvedAt).toLocaleString(i18n.resolvedLanguage) })}
          </p>
        }
        <p className="fs-5 fw-bold">{(data.info.missedPath === 0 && data.info.wrongPath === 0) ? t("maze-check-path-found") : t("maze-check-path-not-found")}</p>
        <p className="fs-5 fw-bold fst-italic">{t("maze-check-summary")}</p>
        <ul>
          {data.info.correct !== 0 ? <li>{t("maze-check-correct")}: <b>{data.info.correct}</b></li> : null}
          {data.info.incorrect !== 0 ? <li>{t("maze-check-wrong")}: <b>{data.info.incorrect}</b></li> : null}
          <br />
          {data.info.correctPath !== 0 ? <li>{t("maze-check-correct-path")}: <b>{data.info.correctPath}</b></li> : null}
          {data.info.missedPath !== 0 ? <li>{t("maze-check-missed-path")}: <b>{data.info.missedPath}</b></li> : null}
          {data.info.wrongPath !== 0 ? <li>{t("maze-check-wrong-path")}: <b>{data.info.wrongPath}</b></li> : null}
        </ul>
      </Alert>

      <p>{t("maze-check-legend")}</p>

      <Row className="justify-content-md-center mb-3">
        {resultType === ResultType.COMPUTATIONS ?
          <>
            <Col xs={12} md={3}>
              <div className="yellow"><CenteredInfoText t={t}/></div>
              <div>{t("maze-check-correct-correct-path")}</div>
            </Col>
            <Col xs={12} md={3}>
              <div className="correct"><CenteredInfoText t={t}/></div>
              <div>{t("maze-check-correct")}</div>
            </Col>
            <Col xs={12} md={3}>
              <div className="wrong"><CenteredInfoText t={t}/></div>
              <div>{t("maze-check-wrong")}</div>
            </Col>
          </>
        :
          <>
            <Col>
              <div className="yellow"><CenteredInfoText t={t}/></div>
              <div>{t("maze-check-correct-correct-path")}</div>
            </Col>
            <Col>
              <div className="correct-path"><CenteredInfoText t={t}/></div>
              <div>{t("maze-check-correct-path")}</div>
            </Col>
            <Col>
              <div className="wrong-path"><CenteredInfoText t={t}/></div>
              <div>{t("maze-check-wrong-path")}</div>
            </Col>
            <Col>
              <div className="missed-path"><CenteredInfoText t={t}/></div>
              <div>{t("maze-check-missed-path")}</div>
            </Col>
          </>
        }
      </Row>

      <center className="mb-3">
        <Button
          onClick={() => setResultType(ResultType.COMPUTATIONS)}
          disabled={resultType === ResultType.COMPUTATIONS}
          className="me-2"
        >
          {t("maze-check-result-type-computations")}
        </Button>
        <Button
          onClick={() => setResultType(ResultType.PATH)}
          disabled={resultType === ResultType.PATH}
        >
          {t("maze-check-result-type-path")}
        </Button>
      </center>

      <div className="maze mb-3" style={{gridTemplateColumns: `repeat(${data.width}, 1fr)`, minWidth: `${75 * data.width}px`}}>
        {Array.from({length: data.height}).map((_, i) => (
          Array.from({length: data.width}).map((__, j) => {
            const isStart = data.start[0] === j && data.start[1] === i;
            const isEnd = data.end[0] === j && data.end[1] === i;
            const cell = data.data[i][j];

            //Build the style...
            let cellStyle = "maze-cell";

            //If the cell is the start or the end
            if (Object.keys(cell).length === 0) {
              cellStyle += " yellow";
            }
            //If the cell was not solved
            else if (Object.keys(cell).length === 2) {
              //If the cell is part of the path and we are displaying the path
              if (cell.isUserPath && cell.isMazePath && resultType === ResultType.PATH) {
                cellStyle += " correct-path";
              } else if (cell.isMazePath && resultType === ResultType.PATH) {
                cellStyle += " missed-path";
              } else if (cell.isUserPath && resultType === ResultType.PATH) {
                cellStyle += " wrong-path";
              }
            }
            //If the cell was solved
            else {
              const isCorrect = cell.result === cell.expectedResult;
              const isCorrectPath = cell.isUserPath && cell.isMazePath;

              //Is it correct and part of the path?
              if (isCorrect && isCorrectPath) {
                cellStyle += " yellow";
              }
              //Or...
              else {
                //If we are displaying the computations
                if (resultType === ResultType.COMPUTATIONS) {
                  if (isCorrect) {
                    cellStyle += " correct";
                  } else {
                    cellStyle += " wrong";
                  }
                }
                //If we are displaying the path
                else {
                  const isWrongPath = cell.isUserPath && !cell.isMazePath;
                  const isMissedPath = !cell.isUserPath && cell.isMazePath;

                  //If the cell is part of the path and the user"s path
                  if (isCorrectPath) {
                    cellStyle += " correct-path";
                  }
                  //If the cell is NOT part of the path but IS part of the user"s path
                  else if (isWrongPath) {
                    cellStyle += " wrong-path";
                  }
                  //If the cell is part of the path but NOT of the user"s path
                  else if (isMissedPath) {
                    cellStyle += " missed-path";
                  }
                }
              }
            }

            return (
              <div key={`${i}-${j}`} className={cellStyle}>
                <div className="maze-cell-content" style={{ height: "100%" }} >
                  {isStart ? t("maze-start")
                    : (isEnd ? t("maze-end")
                      : (cell.result ? <center>{cell.expectedResult}<br /><i>{cell.operation}</i><br />{cell.result}</center> : "")
                    )
                  }
                </div>
              </div>
            );
          })
        ))}
      </div>
    </>
  );
}


/**
 * CenteredInfoText renders the centered information text for the legends.
 */
const CenteredInfoText = ({ t }) => <div style={{textAlign: "center"}}>
  {t("maze-check-expected-result")}<br /><i>{t("maze-check-operation")}</i><br />{t("maze-check-your-result")}
</div>;



/**
 * Checks the parameters passed to the CheckResults component.
 */
function checkParameters(data) {
  if (data === undefined) {
    throw new Error("data is required.");
  }
  if (typeof data !== "object") {
    throw new Error("data must be an object.");
  }
  if (data.width === undefined) {
    throw new Error("data.width is required.");
  }
  if (typeof data.width !== "number") {
    throw new Error("data.width must be a number.");
  }
  if (data.height === undefined) {
    throw new Error("data.height is required.");
  }
  if (typeof data.height !== "number") {
    throw new Error("data.height must be a number.");
  }
  if (data.start === undefined) {
    throw new Error("data.start is required.");
  }
  if (!Array.isArray(data.start)) {
    throw new Error("data.start must be an array.");
  }
  if (data.start.length !== 2) {
    throw new Error("data.start must have 2 elements.");
  }
  if (data.end === undefined) {
    throw new Error("data.end is required.");
  }
  if (!Array.isArray(data.end)) {
    throw new Error("data.end must be an array.");
  }
  if (data.end.length !== 2) {
    throw new Error("data.end must have 2 elements.");
  }
  if (data.data === undefined) {
    throw new Error("data.data is required.");
  }
  if (!Array.isArray(data.data)) {
    throw new Error("data.data must be an array.");
  }
  if (data.data.length !== data.height) {
    throw new Error("data.data must have the same height as data.height.");
  }
  if (data.data.some(row => !Array.isArray(row))) {
    throw new Error("data.data must be an array of arrays.");
  }
  if (data.data.some(row => row.length !== data.width)) {
    throw new Error("data.data must have the same width as data.width.");
  }
  if (data.data.some(row => row.some(cell => typeof cell !== "object"))) {
    throw new Error("data.data must be an array of arrays of objects.");
  }
  if (data.data.some(row => row.some(cell => {
    //Check if the cell is empty
    if (Object.keys(cell).length === 0) return false;

    //Check if the cell has only isUserPath and isMazePath properties
    if (Object.keys(cell).length === 2 && "isUserPath" in cell && "isMazePath" in cell) {
      if (typeof cell.isUserPath === "boolean" && typeof cell.isMazePath === "boolean") return false;
    }

    //Check if the cell has all five properties
    if (Object.keys(cell).length === 5 && "isUserPath" in cell && "isMazePath" in cell && "result" in cell && "expectedResult" in cell && "operation" in cell) {
      if (typeof cell.isUserPath === "boolean" && typeof cell.isMazePath === "boolean" && typeof cell.result === "number" && typeof cell.expectedResult === "number" && typeof cell.operation === "string") return false;
    }

    //If none of the above conditions are met, the cell is invalid
    return true;
  }))) {
    throw new Error("data.data must be an array of arrays of objects with either no properties, two properties (isUserPath, isMazePath), or five properties (isUserPath, isMazePath, result, expectedResult, operation). All properties must be of the correct type.");
  }
  if (data.info === undefined) {
    throw new Error("data.info is required.");
  }
  if (typeof data.info !== "object") {
    throw new Error("data.info must be an object.");
  }
  if (data.info.userType !== undefined && typeof data.info.userType !== "string") {
    throw new Error("data.info.userType must be a string.");
  }
  if (data.info.solutionId !== undefined && typeof data.info.solutionId !== "number") {
    throw new Error("data.info.solutionId must be a number.");
  }
  if (data.info.mazeId !== undefined && typeof data.info.mazeId !== "number") {
    throw new Error("data.info.mazeId must be a number.");
  }
  if (data.info.nickname === undefined) {
    throw new Error("data.info.nickname is required.");
  }
  if (typeof data.info.nickname !== "string") {
    throw new Error("data.info.nickname must be a string.");
  }
  if (data.info.solvedAt !== undefined && typeof data.info.solvedAt !== "number") {
    throw new Error("data.info.solvedAt must be a number.");
  }
  if (data.info.correct === undefined) {
    throw new Error("data.info.correct is required.");
  }
  if (typeof data.info.correct !== "number") {
    throw new Error("data.info.correct must be a number.");
  }
  if (data.info.incorrect === undefined) {
    throw new Error("data.info.incorrect is required.");
  }
  if (typeof data.info.incorrect !== "number") {
    throw new Error("data.info.incorrect must be a number.");
  }
  if (data.info.correctPath === undefined) {
    throw new Error("data.info.correctPath is required.");
  }
  if (typeof data.info.correctPath !== "number") {
    throw new Error("data.info.correctPath must be a number.");
  }
  if (data.info.missedPath === undefined) {
    throw new Error("data.info.missedPath is required.");
  }
  if (typeof data.info.missedPath !== "number") {
    throw new Error("data.info.missedPath must be a number.");
  }
  if (data.info.wrongPath === undefined) {
    throw new Error("data.info.wrongPath is required.");
  }
  if (typeof data.info.wrongPath !== "number") {
    throw new Error("data.info.wrongPath must be a number.");
  }
}
